<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Fukui. Select popular facilities and restaurants in Fukui area. You can choose 3  of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The same facility can only be used once, and the same facility can not be re-entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period:<span> 7/1~7/7.</span><br>
          ● Available Facilities: You Can Use <span>3</span>  Facilities During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukui/en/havefun_title-en.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukui/01_EN.jpg'),
          title: ['One-day Unlimited Ticket for All Echizen Railway Lines'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-1-1, Chuo, Fukui City, Fukui Prefecture (Echizen Railway Fukui Station Counter)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.echizen-tetudo.co.jp/'] },
                { cid: 2, text: ['Route map available: '], noColor:true },
                { cid: 3, text: ['https://www.echizen-tetudo.co.jp/route/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Product content: You can ride for unlimited times on Echizen Railway's Mikuni Awara Line and Katsuyama Eiheiji Line for one day.",'<br>※Not applicable: route bus・Fukui Railway'] },
                { cid: 2, text: ['Please be sure to redeem this1-day unlimited ticket before using it.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hffukui/02_EN.jpg'),
          title: ['Available at 5 Stores in Front of Fukui Station! Soba Gourmet Tour ♪ Soba Tour Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-2-1, Chuo, Fukui City, Fukui Prefecture (Happiring 1F General Counter)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.happiring.com/event/detail.php?cd=1409'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Available stores: Soba Restaurant Maruhachi, Echizen Soba Miyoshiya Main Store, Amida Soba Fukunoi Store, Fukui City Tourist Products Fukufukukan Fukufuku Chaya, Echizen Soba Club.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.','<br>※It may not be available due to temporary closure or full bookings.'] },
                { cid: 3, text: ['Please go to the general counter on Happiring 1F and present the QR code for coupon redemption.'] },
                { cid: 4, text: ['Go to the selected store and present the coupon when ordering. We will deduct the corresponding number of coupons based on that total bill.','<br>※Applicable meal sets may vary by store.','<br>※The number of coupons that can be used may vary by store.'] },
                { cid: 5, text: ['One coupon contains 4 coupons, and each comes with a face value of 300 yen.'] },
                { cid: 6, text: ['Coupons (physical coupons) are valid for six months after redemption.Coupons (physical coupons) are valid for six months after redemption.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukui/03_EN.jpg'),
          title: ['JR Fukui Station Building Complex "Happiring" 1,000-yen Coupon (general use for applicable stores inside)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-2-1, Chuo, Fukui City, Fukui Prefecture (Happiring 1F General Counter)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.happiring.com/english/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['Please present the coupon at each store when checking out. (The QR code cannot be used directly. Please be sure to redeem it before use.)'] },
                { cid: 3, text: ['This coupon cannot be exchanged for cash or change.'] },
                { cid: 4, text: ['Cannot be used outside the applicable stores. (Coupons are not applicable to the following: Lottery shops, Family Marts, Seiren Planet)'] },
                { cid: 5, text: ['Coupons (physical) are valid for six months after redemption.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hffukui/04_EN.jpg'),
          title: ['Fukui Maruoka Castle Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-59 Maruoka-cho, Sakai City, Fukui Prefecture (Maruoka Castle Ticketing Office)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://enjoy.pref.fukui.lg.jp/en/spot/spot-20/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Product details: it includes admission fees for "Maruoka Castle", "Maruoka History and Folk Museum", and "Brief Message from the Heart Museum"'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['Re-admission is not allowed.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hffukui/05_EN.jpg'),
          title: ['Awara Onsen "Mimatsu" One-day Bathing (including towel rental)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                "26-10, Funatsu, Awara City, Fukui Prefecture (1st floor's counter)"],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.mimatu.net/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Product details:it includes hot spring entrance fee and bath towel rental fees'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ["You can only enter the hot spring area once. The Sun Palace is a men's bathhouse, and the Moon Palace is a women's bathhouse."] },
              ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hffukui/06_EN.jpg'),
          title: ['Echizen Matsushima Aquarium Admission 1,500-yen Discount Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '74-2-3, Saki, Mikuni-cho, Sakai City, Fukui Prefecture (Ticketing Window)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.echizen-aquarium.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,500-yen coupon. (It can only be used as a discount for the admission fee)','<br>※No change will be given back.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['Due to typhoons, heavy snowfalls, disasters, etc., there may be temporary closures. Please note that early closing or late opening decisions may be made by the facility for the mentioned circumstances.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hffukui/07_EN.jpg'),
          title: ['Dinosaur Museum Admission Ticket (permanent exhibition)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '51-11, Terao, Muraoka-cho, Katsuyama City, Fukui Prefecture (Main Building General Counter)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.dinosaur.pref.fukui.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance for business hours.'] },
                { cid: 2, text: ['The museum may be temporarily closed due to exhibit replacement or facility regular inspection.'] },
                { cid: 3, text: ['You can enter the museum more than once if within the date written on the admission ticket.'] },
                ]
            }
          ]
        },
        // 
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukui&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukui-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>